import { animated } from '@react-spring/web'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { useMediaQuery } from '@kaliber/use-media-query'

import { routeMap } from '/routeMap'
import { useScrollProgressionScalingCard } from '/machinery/useScrollProgressionScalingCard'
import { useParallax } from '/machinery/useParallax'

import { HeroXxlCampaign } from '/features/pageOnly/XxlCampaign/HeroXxlCampaign'
import { ScalingContainerBorder, ScalingContainerContent } from '/features/buildingBlocks/ScalingContainer'
import { ContentWrapper } from '/features/buildingBlocks/ContentWrapper'
import { ContainerMd, ContainerXl } from '/features/buildingBlocks/Container'
import { LargeIntroTextCentered } from '/features/pageOnly/LargeIntroText'
import { ContentWithoutUniversalComponents } from '/features/buildingBlocks/ContentWithoutUniversalComponents'
import { ImageCover } from '/features/buildingBlocks/Image'
import { Icon } from '/features/buildingBlocks/Icon'

import mediaStyles from '/cssGlobal/media.css'
import styles from './XxlCampaignPageApp.css'

import iconArrow from '/images/icons/arrow-right.raw.svg'

export default function XxlCampaignPageApp({ introduction, subpages, content }) {
  const { elementRef, scale, y } = useScrollProgressionScalingCard()

  return (
    <div className={styles.component}>
      <HeroXxlCampaign layoutClassName={styles.heroLayout} />

      <ScalingContainerBorder style={{ scaleX: scale, y }} layoutClassName={styles.contentBorderLayout} />

      <ScalingContainerContent style={{ scaleX: scale }} {...{ elementRef }}>
        <ContentWrapper>
          <ContainerMd>
            <LargeIntroTextCentered value={introduction} />
          </ContainerMd>

          <ContainerXl>
            {Boolean(subpages?.length) && <SubpageCards items={subpages} />}
          </ContainerXl>

          {content && <ContentWithoutUniversalComponents {...{ content }} />}
        </ContentWrapper>
      </ScalingContainerContent>
    </div>
  )
}

function SubpageCards({ items }) {
  return (
    <div className={styles.componentSubpageCards}>
      {items.map((item, i) => <Card key={i} {...{ item }} />)}
    </div>
  )
}

function Card({ item }) {
  const isMounted = useRenderOnMount()
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  const { parallaxRef, parallaxStyle } = useParallax({ distance: 50, springConfig: {  tension: 500, friction: 35 } })

  const { title, shareImage } = item || {}
  const aspectRatio = isViewportMd ? 7 / 5 : 5 / 7

  return (
    <div ref={parallaxRef} className={styles.componentCard}>
      {isMounted && shareImage && (
        <animated.div className={styles.imageContainer} style={parallaxStyle}>
          <ImageCover image={shareImage} layoutClassName={styles.imageLayout} {...{ aspectRatio }} />
        </animated.div>
      )}

      <CardButton layoutClassName={styles.buttonLayout} {...{ item, title }} />
    </div>
  )
}

function CardButton({ item, title, layoutClassName = undefined }) {
  return (
    <a
      href={determineDocumentPathSync({ document: item, routeMap })}
      data-x='link-to-campaign-subpage'
      className={cx(styles.componentCardButton, layoutClassName)}
    >
      <span className={styles.buttonLabel}>{title}</span>

      <div className={styles.iconContainer}>
        <Icon icon={iconArrow} layoutClassName={styles.iconLayout} />
      </div>
    </a>
  )
}
