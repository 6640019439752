import { sequence } from '@kaliber/math'

export function useFrames(framesCount) {
  const frames = React.useMemo(
    () => {
      return sequence(framesCount).map((_, i) => `${i + 1}`)
    },
    [framesCount]
  )

  return frames
}
