import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { useMediaQuery } from '@kaliber/use-media-query'

import { FramesOnScroll } from '/features/buildingBlocks/FramesOnScroll'

import mediaStyles from '/cssGlobal/media.css'
import styles from './HeroXxlCampaign.css'

export function HeroXxlCampaign({ layoutClassName = undefined }) {
  const isMounted = useRenderOnMount()
  const isViewportLg = useMediaQuery(mediaStyles.viewportLg)
  const folder = isViewportLg ? 'desktop' : 'mobile'

  return (
    <section data-x='hero' className={cx(styles.component, layoutClassName)}>
      {isMounted && (
        <FramesOnScroll
          videoName={`xxlHero/${folder}`}
          framesCount={74}
          layoutClassName={styles.framesOnScrollLayout}
        />
      )}
    </section>
  )
}
