import { useSpring } from '@react-spring/web'
import { useScrollProgression, triggers }  from '@kaliber/scroll-progression'
import { lerp } from '@kaliber/math'

export function useScrollProgressionScalingCard() {
  const [{ scale }, api] = useSpring(() => ({
    scale: 0.85,
    config: { tension: 500, friction: 35 }
  }))

  const { y } = useSpring({
    from: { y: 50 },
    to: { y: 0 },
    config: { tension: 500, friction: 35 }
  })

  const elementRef = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.bottom() },
    end: { element: triggers.top(), scrollParent: triggers.custom(0.5) },
    onChange(progression) {
      api.start({ scale: lerp({ start: 0.9, end: 1, input: easeOut(progression) }) })
    }
  })

  return {
    elementRef,
    scale,
    y
  }
}

function easeOut(x) {
  return Math.sin((x * Math.PI) / 2)
}
