import { useScroll, animated, config, useSpringValue, to, useSpring } from '@react-spring/web'
import { useElementSize } from '@kaliber/use-element-size'

import { useWindowEventListener } from '/machinery/useWindowEventListener'
import { useFrames } from '/machinery/useFrames'

import styles from './FramesOnScroll.css'

export function FramesOnScroll({ videoName, framesCount, loop = undefined, layoutClassName = undefined }) {
  const { size: { height: elementHeight }, ref: elementRef } = useElementSize()
  const frames = useFrames(framesCount)
  const { scrollYProgress } = useScroll({ config: config.slow })
  const scrollHeight = useSpringValue(0)

  const { value: autoPlayOffset } = useSpring({
    from: { value: 0 },
    to: { value: frames.length * 0.50 },
    config: config.slow
  })

  useWindowEventListener('resize', handleResize)

  return (
    <div ref={elementRef} className={cx(styles.component, layoutClassName)}>
      {frames.map((filename, index) => (
        <animated.picture
          key={index}
          aria-hidden='true'
          role='presentation'
          data-visible={handleCurrentIndex(index)}
          className={styles.frame}
        >
          <AVIF {...{ videoName, filename }} />
          <WebP {...{ videoName, filename }} />
          <Image {...{ videoName, filename }} />
        </animated.picture>
      ))}
    </div>
  )

  function handleCurrentIndex(index) {
    return to([autoPlayOffset, scrollYProgress, scrollHeight], (offset, progress, height) => {
      const frame = Math.ceil((progress / (elementHeight / height)) * (frames.length * 0.75) + offset)
      const useFrame = loop ? frame % frames.length : frame
      return index === (Math.min(Math.max(useFrame, 0), frames.length - 1) ?? 0)
    })
  }

  function handleResize() {
    scrollHeight.set(document.documentElement.scrollHeight)
  }
}

function AVIF({ videoName, filename }) {
  return (
    <source
      className={styles.componentAVIF}
      srcSet={`/images/sequences/${videoName}/avif/${filename}.avif`}
      type="image/avif"
    />
  )
}

function WebP({ videoName, filename }) {
  return (
    <source
      className={styles.componentWebP}
      srcSet={`/images/sequences/${videoName}/webp/${filename}.webp`}
      type="image/webp"
    />
  )
}

function Image({ videoName, filename }) {
  return (
    <img
      decoding='sync'
      loading='eager'
      className={styles.componentImage}
      src={`/images/sequences/${videoName}/jpg/${filename}.jpg`}
      role='presentation'
      alt=''
    />
  )
}
