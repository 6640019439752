import { animated } from '@react-spring/web'

import styles from './ScalingContainer.css'

export function ScalingContainerBorder({ style, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentBorder, layoutClassName)}>
      {style && <animated.span className={styles.borderRadius} style={{ translateY: '1px', ...style }} />}
    </div>
  )
}

export function ScalingContainerContent({ elementRef, style, children }) {
  return (
    <animated.div ref={elementRef} className={styles.componentContent} {...{ style }}>
      {children}
    </animated.div>
  )
}
